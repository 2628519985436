require("./bootstrap");

window.Vue = require("vue/dist/vue");
window.moment = require("moment");
window.toastr = require("toastr");

//Disable develoment project
Vue.config.productionTip = false;

import {
  auth,
  signInAnonymously,
  database,
  set,
  ref,
  onValue
} from "./firebase";

const app = new Vue({
  el: "#app",

  data: {
    messages: []
  },

  mounted() {
    this.checkSession();
  },

  methods: {
    checkSession() {
      axios.get("/auth-user").then(response => {
        if (response.data.success === true) {
          let userId = response.data.user.userId;
          let keyId = response.data.user.sessionId;

          signInAnonymously(auth)
            .then(() => {
              set(ref(database, "users/" + userId), {
                key_id: keyId
              });

              const userRef = ref(database, "users/" + userId);
              onValue(userRef, snapshot => {
                const data = snapshot.val();
                if (data.key_id != keyId) {
                  toastr.error(
                    "Login efetuado em outro dispositivo.",
                    "Alerta",
                    {
                      timeOut: 2500,
                      progressBar: true
                    }
                  );
                  setTimeout(function() {
                    axios.post("/logout").then(function() {
                      window.location.href = "/";
                    });
                  }, 3500);
                }
              });
            })
            .catch(error => {
              console.log("Erro com sessão firebase.");
            });
        }
      });
    }
  }
});
