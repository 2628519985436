import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getDatabase, set, ref, onValue } from "firebase/database";

var firebaseConfig = {
  apiKey: process.env.MIX_FIREBASE_API_KEY,
  authDomain: process.env.MIX_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.MIX_FIREBASE_DATABASE_URL,
  storageBucket: process.env.MIX_FIREBASE_STORAGE_BUCKET,
  projectId: "ivalonline-cd07f",
  messagingSenderId: "501737207877",
  appId: "1:501737207877:web:5db742e849995ec1144b2c",
  measurementId: "G-NCEPCH0W7D"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

export { auth, signInAnonymously, database, set, ref, onValue };
